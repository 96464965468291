<template>
  <div class="home" style="margin-top: 2%">
    <div class="home-resolve">
      <div class="home-resolve-banner">
        <p style="text-align: center;">
          <img  src="@/assets/banner/ttlinkbanner.png" style=" cursor: pointer;" alt="跳转链接" @click="openLink">
        </p>
        <template v-if="localStorageType==2">
          <div><h1>合作类型</h1></div>
          <img src="@/assets/banner/cooperation_way_com.png" alt=""/>
          <div style="margin-top: 5%"><h1>合作权益</h1></div>
          <img src="@/assets/banner/cooperation_rights_com.png" alt=""/>
        </template>
        <template v-else-if="localStorageType==1">
          <div><h1>合作类型</h1></div>
          <img src="@/assets/banner/cooperation_way_his.png" alt=""/>
          <div style="margin-top: 5%"><h1>合作权益</h1></div>
          <img src="@/assets/banner/cooperation_rights_his.png" alt=""/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
  methods : {
    openLink() {
      const  url = 'https://crowdsourcing.tntlinking.com/dashboard?link=openhis'
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped lang="scss">
.home {
  &-banner {
    position: relative;

    img {
      width: 100%;
    }

    &-content {
      position: absolute;
      top: 140px;
      left: 360px;

      h1 {
        font-size: 48px;
        color: #333;
        margin-bottom: 40px;
      }
    }
  }


  table {
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid #ccc;
      padding: 8px;
    }

    td.left-column {
      width: 30%;
    }

    td.right-column {
      width: 70%;
    }
  }

  &-info {
    position: relative;
    z-index: 10;
    top: -50px;
    display: flex;
    justify-content: center;

    &-cell {
      width: 380px;
      height: 100px;
      display: flex;
      align-items: center;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;

      img {
        width: 40px;
        margin-right: 24px;
        margin-left: 24px;
      }

      .title {
        font-size: 18px;
        color: #333333;
        line-height: 35px;
      }

      .desc {
        font-size: 15px;
        color: #8791a1;
        line-height: 35px;
      }
    }

    &-cell + &-cell {
      margin-left: 24px;
    }
  }

  &-title {
    font-size: 36px;
    color: #262c33;
    line-height: 35px;
    text-align: center;
  }

  &-subtitle {
    font-size: 20px;
    color: #262c33;
    line-height: 35px;
    text-align: center;
  }

  .divider {
    text-align: center;
    margin-top: 10px;

    label {
      display: inline-block;
      width: 240px;
      height: 1px;
      background-color: #0f1010;
    }
  }

  &-resolve {
    &-banner {
      margin: 0 auto;
      width: 1200px;
      position: relative;
      text-align: center;

      img {
        width: 1200px;
      }

      &-content {
        position: absolute;
        right: 70px;
        top: 105px;
      }
    }

    &-list {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-cell {
      display: flex;
      width: 542px;
      padding: 24px;
      box-shadow: 4px 8px 8px 8px #f5f5f5;
      border-radius: 6px 6px 6px 6px;
      background-color: white;
      margin-top: 12px;

      img {
        width: 64px;
        height: 64px;
        margin-right: 24px;
      }

      .title {
        font-size: 24px;
        color: #262c33;
        line-height: 35px;
      }

      .desc {
        font-size: 16px;
        color: #262c33;
        line-height: 24px;
        margin-top: 8px;
      }

      .op {
        margin-top: 8px;

        span {
          font-size: 14px;
          color: #797b7f;
          cursor: pointer;
        }

        span + span {
          margin-left: 10px;
        }
      }

      .op1 {

        span {
          font-size: 16px;
          color: #748db1;
          cursor: pointer;
        }

        span + span {
          margin-left: 20px;
        }
      }
    }
  }

  &-select {
    width: 100%;
    margin: 54px 0;
  }


  &-banner2 {
    position: relative;
    text-align: center;


    img {
      width: 100%;
    }

    &-title2 {
      font-size: 32px;
      color: #262c33;
      line-height: 20px;
      text-align: center;
    }

    &-subtitle2 {
      font-size: 18px;
      color: #262c33;
      text-align: center;
    }

    &-content2 {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }


  }


  &-news {
    &-content {
      display: flex;
      margin: 90px auto 0;
      width: 1170px;

      img {
        width: 556px;
        margin-right: 70px;
      }

      &-cell {
        padding-bottom: 40px;
        border-bottom: 1px solid #eee;

        .title {
          font-size: 22px;
          color: #262c33;
          line-height: 35px;
        }

        .title:hover {
          color: red;
          cursor: pointer;
        }

        .desc {
          font-size: 16px;
          color: #a8abad;
          line-height: 24px;
          margin-top: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      &-cell + &-cell {
        padding-top: 24px;
      }
    }

    &-more {
      display: flex;
      justify-content: center;
      margin-top: 42px;
      margin-bottom: 50px;

      div {
        cursor: pointer;
        padding: 8px 50px;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #333;
      }
    }
  }
}
</style>
